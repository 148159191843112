<template>
<title> Luke / Home </title>
<link rel="icon" type="image/x-icon" href="https://i.ibb.co/0XBwd2H/pfp.gif">

    <div class="All Home relative">
      
      <img id="gif2" class="MainImage mx-auto rounded-full z-0" src="https://i.ibb.co/0XBwd2H/pfp.gif">
      <img id="gif1" class="MPOS ml-auto mr-auto mb-auto absolute inset-0 z-10 pointer-events-none" src="https://i.ibb.co/FHfwpJC/a.gif">

      <h1 class="mt-2 text-6xl text-white"><b> Luke </b> </h1>
      <p class="-mt-2 text-2xl text-gray-500"> <i> he/him </i> </p>
      <p class="mt-5 text-6xl text-white"> I'm weird</p>
      
      <p class="mt-20 text-3xl text-white"> this page is really empty so heres a cat on a skateboard :) </p>
      <img class="mt-5 mx-auto rounded-lg" src="https://i.ibb.co/fCVNrQj/aaaa.gif">

    </div>

</template>

<script src="./scripts/load.js"></script>
